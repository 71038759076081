<script lang="ts" setup>
import AppStore from './reusables/AppStore.vue'
import GooglePlay from './reusables/GooglePlay.vue'

const { t } = useI18n()
</script>

<template>
  <div>
    <div
      class="py-3.5 max-w-237.25 mx-auto min-h-61.25 px-10 flex flex-col items-center justify-center rounded-3 px-5 mx-5 lg:mx-auto relative"
    >
      <div
        class="text-center text-2xl leading-normal tracking-wide lg:text-[1.75rem]"
      >
        {{ t('join-the-revolution') }}
      </div>
      <div class="flex flex-row mt-8 gap-5 flex-wrap justify-center">
        <AppStore />
        <GooglePlay />
      </div>

      <i class="i-belong:join-revolution w-full h-full absolute -z-1" />
    </div>
  </div>
</template>
